import React, { Ref, useEffect, useMemo, useState } from "react";
import { CheckIcon, CirclePlusIcon } from "@suns/design-system/icons";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../Command/Command";
import { Button } from "../Button/Button";
import { Separator } from "../Separator/Separator";
import { Text } from "../Text/Text";
import { Badge } from "../Badge/Badge";
import { cn } from "../../utils";
import { Flex } from "../Flex/Flex";

export interface FilterOption {
  key: string;
  name: string;
  badgeValue?: string | React.ReactNode;
}

export interface FilterProps<T extends FilterOption = FilterOption> {
  shouldFilter?: boolean;
  title?: string;
  value: string[];
  onChange: (keys: string[], options: T[]) => void;
  options: T[];
  className?: string;
  avoidCollisions?: boolean;
  loading?: boolean;
  onSearchChange?: (value: string) => void;
  isDisabled?: (key: string) => boolean;
  noClear?: boolean;
  innerRef?: Ref<HTMLButtonElement>;
}

const Filter = <T extends FilterOption>({
  shouldFilter = true,
  title,
  value = [],
  onChange,
  options,
  className,
  avoidCollisions,
  loading,
  onSearchChange,
  isDisabled,
  noClear,
  innerRef,
}: FilterProps<T>) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      onSearchChange?.("");
    }
  }, [open, onSearchChange]);

  const selectedOptions = useMemo(
    () => options.filter((option) => value.includes(option.key)),
    [options, value]
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="dashed"
          className={cn("h-auto min-h-10 bg-white lg:w-auto", className)}
          ref={innerRef}
        >
          <CirclePlusIcon className="size-4 flex-shrink-0" />
          {loading ||
            (title && (
              <Text size="sm" className="ml-3">
                {loading ? "Loading..." : title}
              </Text>
            ))}
          <Badge
            variant="secondary"
            className="ml-2 rounded-md px-2 font-normal lg:hidden"
          >
            {value?.length}
          </Badge>
          {value?.length > 0 && (
            <>
              <Separator
                orientation="vertical"
                className="mx-3 h-4 xs:hidden sm:hidden md:hidden lg:flex"
              />
              <Flex className="hidden lg:flex" wrap={true} gap="xs">
                {value.length > 3 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-md px-2 font-normal "
                  >
                    {value.length} selected
                  </Badge>
                ) : (
                  selectedOptions.map((option) => (
                    <Badge
                      variant="secondary"
                      key={option.key}
                      className="rounded-md px-2 font-normal "
                    >
                      {option.name}
                    </Badge>
                  ))
                )}
              </Flex>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={avoidCollisions}
        className="w-[250px] p-0"
        align="start"
      >
        <Command shouldFilter={shouldFilter}>
          <CommandInput onValueChange={onSearchChange} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option, index) => {
                const isSelected = value?.includes(option.key);
                const disabled = Boolean(isDisabled?.(option.key));
                return (
                  <CommandItem
                    disabled={disabled}
                    key={index}
                    onSelect={() => {
                      const newSelectedOptions = isSelected
                        ? selectedOptions.filter(
                            ({ key }) => key !== option.key
                          )
                        : [...selectedOptions, option];
                      onChange(
                        newSelectedOptions.map(({ key }) => key),
                        newSelectedOptions
                      );
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-black",
                        disabled
                          ? "bg-gray-500 text-secondary-foreground"
                          : isSelected
                            ? "bg-secondary text-secondary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon />
                    </div>

                    <span className="mr-auto">{option.name}</span>
                    {option.badgeValue ? (
                      <Badge variant="default">{option.badgeValue}</Badge>
                    ) : null}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {value?.length > 0 && !noClear && (
              <>
                <Separator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onChange([], [])}
                    className="justify-center text-center"
                  >
                    Clear selections
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

Filter.displayName = "Filter";

export { Filter };
