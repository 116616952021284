import * as React from "react";
import * as RSelect from "@radix-ui/react-select";
import { Check, ChevronDown, ChevronUp } from "../../../icons";

import { cn } from "../../utils";

interface SelectProps extends React.ComponentProps<typeof RSelect.Root> {
  className?: string;
  placeholder?: string;
}

const Select = React.forwardRef<
  React.ElementRef<typeof RSelect.Trigger>,
  SelectProps
>(({ className, children, placeholder = "Select...", ...props }, ref) => (
  <RSelect.Root {...props}>
    <RSelect.Trigger
      ref={ref}
      className={cn(
        `focus:ring-ring flex min-h-10 items-center justify-between rounded-md border
        bg-white px-3 py-2 text-sm ring-offset-background focus:outline-none
        focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
        radix-placeholder:text-muted-foreground [&>span]:line-clamp-1`,
        className
      )}
    >
      <RSelect.Value placeholder={placeholder} />
      <RSelect.Icon asChild>
        <ChevronDown className="ml-2 h-4 w-4 opacity-50" />
      </RSelect.Icon>
    </RSelect.Trigger>

    <RSelect.Portal>
      <RSelect.Content
        className="relative top-2 z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border
          bg-popover p-2 text-popover-foreground shadow-md radix-state-closed:animate-out
          radix-state-closed:fade-out-0 radix-state-closed:zoom-out-95
          radix-state-open:animate-in radix-state-open:fade-in-0
          radix-state-open:zoom-in-95 radix-side-bottom:slide-in-from-top-2
          radix-side-left:slide-in-from-right-2 radix-side-right:slide-in-from-left-2
          radix-side-top:slide-in-from-bottom-2"
        position="popper"
      >
        <RSelect.ScrollUpButton className="flex cursor-default items-center justify-center py-1">
          <ChevronUp className="h-4 w-4" />
        </RSelect.ScrollUpButton>
        <RSelect.Viewport className="p-1">{children}</RSelect.Viewport>
        <RSelect.ScrollDownButton className="flex cursor-default items-center justify-center py-1">
          <ChevronDown className="h-4 w-4" />
        </RSelect.ScrollDownButton>
      </RSelect.Content>
    </RSelect.Portal>
  </RSelect.Root>
));
Select.displayName = RSelect.Root.displayName;

interface SelectGroupProps
  extends React.ComponentPropsWithoutRef<typeof RSelect.Group> {
  label: string;
}

const SelectGroup = React.forwardRef<
  React.ElementRef<typeof RSelect.Group>,
  SelectGroupProps
>(({ className, children, label, ...props }, ref) => (
  <RSelect.Group ref={ref} {...props}>
    <RSelect.Label className="py-1.5 pl-8 pr-2 text-sm font-semibold">
      {label}
    </RSelect.Label>
    {children}
  </RSelect.Group>
));
SelectGroup.displayName = RSelect.Group.displayName;

interface SelectOptionProps
  extends React.ComponentPropsWithoutRef<typeof RSelect.Item> {}

const SelectOption = React.forwardRef<
  React.ElementRef<typeof RSelect.Item>,
  SelectOptionProps
>(({ className, children, ...props }, ref) => (
  <RSelect.Item
    ref={ref}
    className={cn(
      `relative flex w-full cursor-pointer select-none items-center rounded-sm py-2
      pl-8 pr-4 text-sm outline-none hover:bg-muted-100 focus:bg-accent
      focus:text-accent-foreground radix-disabled:pointer-events-none
      radix-disabled:opacity-50`,
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <RSelect.ItemIndicator>
        <Check className="h-4 w-4" />
      </RSelect.ItemIndicator>
    </span>

    <RSelect.ItemText>{children}</RSelect.ItemText>
  </RSelect.Item>
));
SelectOption.displayName = RSelect.Item.displayName;

export { Select, SelectGroup, SelectOption };
