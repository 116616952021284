import * as React from "react";

import { cn, VariantParams, classVariants } from "../../utils";

const badgeVariants = classVariants(
  "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs relative z-[1] font-semibold",
  {
    variants: {
      variant: {
        default: "border border-gray-200 bg-gray-200 text-gray-600",
        primary: "bg-primary text-primary-foreground",
        secondary: "bg-secondary text-secondary-foreground",
        destructive: "bg-destructive text-destructive-foreground",
        outline: "text-foreground border border-gray-300",
      },
      muted: {
        true: "",
      },
    },
    compoundVariants: [
      { variant: "default", muted: true, class: "bg-gray-100 text-gray-400" },
      {
        variant: "primary",
        muted: true,
        class: "bg-primary-400",
      },
      {
        variant: "secondary",
        muted: true,
        class: "text-secondary-400",
      },
      {
        variant: "destructive",
        muted: true,
        class: "text-destructive-400",
      },
    ],
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantParams<typeof badgeVariants> {
  ping?: boolean;
}

export function Badge({
  className,
  variant,
  muted,
  children,
  ping = false,
  ...props
}: BadgeProps) {
  return (
    <span className="relative text-xs">
      <div
        className={cn(badgeVariants({ variant, muted }), className)}
        {...props}
      >
        {children}
      </div>
      {ping && (
        <span
          className={cn(
            badgeVariants({ variant, muted }),
            "absolute left-0 top-0 z-0 block h-full w-full animate-ping-small opacity-50"
          )}
        />
      )}
    </span>
  );
}
