import { createElement, forwardRef } from "react";
import { Icon as IconFn, LucideProps, createLucideIcon } from "lucide-react";
import sIcon from "./icons/sIcon.svg?svgson";
import team from "./icons/team.svg?svgson";
import play from "./icons/play.svg?svgson";
import starbadge from "./icons/starbadge.svg?svgson";
export * from "lucide-react";

export const Team = createLucideIcon("Team", team);
export const Play = createLucideIcon("Play", play);

const ApolloBadgeIcon = forwardRef<SVGSVGElement, LucideProps>((props, ref) => {
  return createElement(IconFn, {
    ref,
    iconNode: starbadge,
    fill: props.color,
    strokeWidth: 0,
    ...props,
  });
});

ApolloBadgeIcon.displayName = "ApolloBadgeIcon";

const SIcon = forwardRef<SVGSVGElement, LucideProps>((props, ref) => {
  return createElement(IconFn, {
    ref,
    iconNode: sIcon,
    fill: "currentColor",
    strokeWidth: 0,
    ...props,
  });
});

SIcon.displayName = "SIcon";

export type Icon = typeof SIcon;
export { SIcon, ApolloBadgeIcon };
