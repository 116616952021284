import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "../../utils";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn(
      `z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md
      outline-none radix-state-closed:animate-out radix-state-closed:fade-out-0
      radix-state-closed:zoom-out-95 radix-state-open:animate-in
      radix-state-open:fade-in-0 radix-state-open:zoom-in-95
      radix-side-bottom:slide-in-from-top-2 radix-side-left:slide-in-from-right-2
      radix-side-right:slide-in-from-left-2 radix-side-top:slide-in-from-bottom-2`,
      className
    )}
    {...props}
  />
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
